#receipt { width: 816px !important; height: 1056px !important; }

@media print {

  html, body {
    height:100%; 
    margin: 0 !important; 
    padding: 0 !important;
    overflow: hidden;
  }

  body {
	  visibility:hidden;
	}
	.printable {
	  visibility:visible;
	  border: none !important;
	}

	body .printable {
	  /* You can have any CSS here to make the view better on print */
	  position:absolute;
	  top:0;
	}


}