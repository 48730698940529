@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwindcss/forms;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

html, body, #root{ height: 100%; }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media print {
  div.top { display: none !important;  }
}